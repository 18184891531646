// vendor styles
import '../node_modules/uikit/dist/css/uikit.css';
import '../node_modules/sumoselect/sumoselect.min.css';
import '../custom/vue/css/ion.rangeSlider.css';
import '../node_modules/jquery-ui/themes/base/core.css';
import '../node_modules/jquery-ui/themes/base/theme.css';
import '../node_modules/jquery-ui/themes/base/sortable.css';
import '../node_modules/jquery-ui/themes/base/datepicker.css';

// vendor js
import $ from 'jquery';
window.$ = $;

import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/widgets/sortable';

import gsap from 'gsap';
window.gsap = gsap;

import select2 from 'select2';
window.select2 = select2;

import {ionRangeSlider} from 'ion-rangeslider';

// set uikit in global variable
import UIkit from 'uikit'
window.UIkit = UIkit;

// icons
import Icons from '../node_modules/uikit/dist/js/uikit-icons';
UIkit.use(Icons);

import owlCarousel from 'owl.carousel';
window.owlCarousel = owlCarousel;

